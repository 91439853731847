@import "_generated-font-mixins.scss";
.ads {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &__header {
    position: relative;
    z-index: 1;

    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: 10px 0 15px;

    & > ul {
      margin-top: 0;
    }
  }

  &__wrapper {
    width: 74%;

    &__empty__search {
      width: 100%;
      margin-top: 73px;
      color: #999;
      text-align: center;

      &__text {
        margin-bottom: 3px;
        font-size: 18px;
      }
    }
  }

  &__filters {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    &__type {
      display: flex;
      flex: 0 0 416.5px;
      justify-content: space-between;
      margin-bottom: 5px;
    }
  }
}

.pagination {
  padding: 80px 0 0;
}
