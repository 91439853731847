@import "_generated-font-mixins.scss";
.wrapper {
  padding-top: 10px;
  border: 1px solid #ebebeb;
  border-top: 0;

  &__item {
    display: flex;
    flex-direction: column;

    padding: 0 14px 10px;

    font-size: 13px;
    line-height: 20px;

    &__title {
      &__category {
        margin-top: 0;
        color: #999;
      }

      &__subcategory {
        cursor: pointer;
        color: #005cc3;

        &:hover {
          color: #c01;
        }
      }
    }

    &__count {
      display: inline;

      margin-left: 7px;

      font-size: 11px;
      font-style: normal;
      color: #999;
    }
  }
}
