@import "_generated-font-mixins.scss";
.filters {
  display: flex;
  flex-direction: column;

  width: 24%;
  margin-left: 20px;

  background-color: #fff;
}

.wrapper {
  width: 100%;
  padding-bottom: 16px;

  &__search {
    border-radius: 4px;
  }
}

.admixer {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  max-width: 240px;

  border-radius: 4px !important;
}
