@import "_generated-font-mixins.scss";
.wrapper {
  margin-bottom: 15px;
  background: #f6f6f8;
  border-radius: 4px;

  &__filters__subscribe {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;

    &__text {
      max-width: 170px;
      font-size: 13px;
      line-height: 15px;
      color: #333;
    }
  }
}
