@import "_generated-font-mixins.scss";
.modal {
  position: fixed;
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  place-content: stretch center;
  align-items: center;

  width: 185px;
  height: 160px;

  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 7px rgb(0 0 0 / 50%);

  &__check {
    width: 40px;
    height: 40px;
    padding: 5px;

    border: 2px solid #73b222;
    border-radius: 50px;

    &::before {
      @include icon-check;

      margin-left: 3px;
      font-size: 17px;
      color: #73b222;
    }
  }

  &__text {
    margin-top: 10px;

    font-size: 15px;
    font-weight: 400;
    line-height: 30px;
    color: #73b222;
    text-align: center;
  }

  &__error {
    margin-top: 10px;

    font-size: 15px;
    font-weight: 400;
    line-height: 30px;
    color: #c01;
    text-align: center;
  }
}
